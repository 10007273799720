<template>
  <div class="card mt-6">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <h4>Scrape</h4>
      </div>
      <!--begin::Card title-->
    </div>
    <div class="card-body pt-0">
      <Datatable
        v-if="websites.data"
        :table-data="websites.data"
        :table-header="columns"
        :enable-items-per-page-dropdown="true"
        :rows-per-page-change="rowsPerPageChange"
        :page-change="pageChange"
        :total="websites.total"
      >
        <template v-slot:cell-website="{ row: scrape }">
          <router-link :to="`scrape/${scrape.website_id}`">{{ scrape.website ? scrape.website.website : ''}}</router-link>
        </template>
        <template v-slot:cell-keywords="{ row: scrape }">
          {{ scrape.website ? scrape.website.keywords : '' }}
        </template>
        <template v-slot:cell-processed="{ row: scrape }">
          {{ scrape.website ? scrape.website.processed : ''}}
        </template>
        <template v-slot:cell-title="{ row: scrape }">
          {{ scrape.website ? scrape.website.title : ''}}
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";

export default {
  name: "Scrape",
  data() {
    return {
      loading: false,
      columns: [
        {
          key: "checkbox"
        },
        {
          name: "Website",
          key: "website",
          sortable: true
        },
        {
          name: "Website Title",
          key: "title",
          sortable: false
        },
        {
          name: "Keywords",
          key: "keywords",
          sortable: false
        },
        {
          name: "Status",
          key: "processed",
          sortable: true
        },
      ],
      pagination: {
        page: 1,
        per_page: 15,
        total: 0
      },
    };
  },
  components: {
    Datatable
  },
  mounted() {
    this.getData();
  },
  computed: {
    websites() {
      return store.getters["SiteScraper/getWebsites"];
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    getData() {
      this.loading = true;
      const queryString = Object.keys(this.pagination).map(key => key + "=" + this.pagination[key]).join("&");

      store.dispatch("SiteScraper/getScrape", {queryString, id: this.$route.params.id}).then(() => this.loading = false);
    },
    rowsPerPageChange(perPage) {
      this.pagination.per_page = perPage;
    },
    pageChange(page) {
      this.pagination.page = page;
    }
  }
};
</script>

<style scoped>

</style>